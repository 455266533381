import * as React from 'react';
import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import * as ga from './ga';
import Button from './Button';

const Wrapper = styled.div`
  padding-bottom: 24px;
`

const Container = styled.div`
  background-color: rgba(126, 140, 224, 0.47);
  border-radius: 2px;
  position: relative;
  padding: 8px;

  ${({ success }) => success && css`
    background-color: rgba(74, 207, 172, 0.6);
  `}
`;

const StatusCode = styled.div`
`;

const Method = styled.div`
`;

const Location = styled.div`
  background-color: rgba(38, 40, 51, 0.47);
  color: rgba(255, 255, 255, 0.9);
  padding: 8px;
  border-radius: 2px;
  line-break: anywhere;
  outline: none;

  &:hover {
    background-color: rgba(38, 40, 51, 0.4);
    color: rgba(255, 255, 255, 1);
  }

  &:focus {
    box-shadow: 0px 0px 3px 0px rgba(200, 200, 200, 0.75);
  }
`;

const StatusRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding-bottom: 8px;
  color: rgba(255, 255, 255, 0.6);
`;

const ActionsRow = styled.div`
  padding-top: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Action = styled.div`
  padding-left: 8px;

  &:first-child {
    padding-left: 0;
  }
`;

const CopiedIndicator = styled.div`
  padding: 0 16px;
  font-size: 14px;
`;

const FinalLocationInput = styled(TextareaAutosize)`
  background-color: rgba(38, 40, 51, 0.47);
  color: rgba(255, 255, 255, 0.9);
  padding: 8px;
  border-radius: 2px;
  line-break: anywhere;
  outline: none;
  border: none;
  width: 100%;
  resize: none;

  &:hover, &:active, &:focus {
    background-color: rgba(38, 40, 51, 0.4);
    color: rgba(255, 255, 255, 1);
  }

  &:focus {
    box-shadow: 0px 0px 3px 0px rgba(200, 200, 200, 0.75);
  }
`;

export default function StatusRecord({
  statusCode,
  status,
  method,
  location,
  isFirst,
  isLast,
}) {
  const [copied, setCopied] = React.useState(false);
  const copiedTimeoutRef = React.useRef();
  const [finalLocation, setFinalLocation] = React.useState(location);

  React.useEffect(() => {
    return () => {
      if (copiedTimeoutRef.current) {
        clearTimeout(copiedTimeoutRef.current);
      }
    };
  }, []);

  const indicateCopied = () => {
    if (copiedTimeoutRef.current) {
      clearTimeout(copiedTimeoutRef.current);
    }

    copiedTimeoutRef.current = setTimeout(() => {
      setCopied(false);
    }, 3000);
    setCopied(true);
  };

  const handleCopyLink = () => {
    ga.trackEvent('User', 'Link copied');

    if (finalLocation) {
      const textField = document.createElement('textarea');
      textField.innerText = finalLocation;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();

      indicateCopied();
    }
  };

  return (
    <Wrapper>
      <Container
        success={isLast}
      >
        <StatusRow>
          <StatusCode>
            {statusCode} {status}
          </StatusCode>
          <Method>
            {method}
          </Method>
        </StatusRow>
        {isLast
        ? (
          <FinalLocationInput
            value={finalLocation}
            spellCheck={false}
            onChange={(ev) => { setFinalLocation(ev.target.value); }}
          />
        )
        : (
          <Location>
            {location}
          </Location>
        )}
        {isLast && (
          <ActionsRow>
            {copied && (
              <CopiedIndicator>
                Link copied ✓
              </CopiedIndicator>
            )}
            <Action>
              <Button
                size="small"
                layout="secondary"
                onClick={handleCopyLink}
              >
                Copy Link
              </Button>
            </Action>
            <Action>
              <Button
                element="a"
                size="small"
                layout="secondary"
                href={finalLocation}
                target="_blank"
              >
                Open Link
              </Button>
            </Action>
          </ActionsRow>
        )}
      </Container>
    </Wrapper>
  );
}
