import ReactGA from 'react-ga';

export function initialize() {
  if (process.env.NODE_ENV === 'development') {
    console.log('Analytics initialize');
  }
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('UA-169382920-1', {
      gaOptions: {
        anonymizeIp: true,
      },
    });
  }
}

export function trackEvent(category, action, value) {
  if (process.env.NODE_ENV === 'development') {
    console.log(`Track: ${category}/${action}${value ? ` = ${value}` : ''}`);
  }
  if (process.env.NODE_ENV === 'production') {
    ReactGA.event({
      category,
      action,
      value,
    });
  }
}

export function pageView() {
  const page = `${window.location.pathname}${window.location.search}`;
  if (process.env.NODE_ENV === 'development') {
    console.log(`Track PageView: ${page}`);
  }
  if (process.env.NODE_ENV === 'production') {
    ReactGA.pageview(page);
  }
}
