/* eslint-disable no-throw-literal*/

import * as React from 'react';
import styled, {
  createGlobalStyle,
  css,
  keyframes,
} from 'styled-components';
import media from "styled-media-query";
import reset from 'styled-reset'
import useKonamiCode from './useKonamiCode';
import * as ga from './ga';
import InputForm from './InputForm';
import StatusRecord from './StatusRecord';
import Button from './Button';
import { ReactComponent as HeaderLogo } from './logo.svg';
import { ReactComponent as MaxLogo } from './maxlogo.svg';
import { ReactComponent as ErrorImage } from './error.svg';
import { ReactComponent as HappyImage } from './happy.svg';
import { ReactComponent as LoaderImage } from './loader.svg';

const GlobalStyle = createGlobalStyle`
  ${reset}
  body, html {
    background-color: rgb(38, 40, 51);
    color: #fff;
  }

  html {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
`

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  width: 100%;
`;

const ContentContainers = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderLogoContainer = styled.div`
  width: 56px;

  ${media.lessThan("medium")`
    width: 50px;
  `}
`;

const HeaderTitle = styled.h1`
  margin: 0;
  padding: 0 0 0 24px;
  line-height: 1;
  font-size: 40px;

  ${media.lessThan("medium")`
    font-size: 30px;
  `}
`;

const Footer = styled.div`
  padding: 80px 0 32px 0;
  width: 100%;


  ${media.lessThan("medium")`
    padding-top: 30px;
  `}
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MaxLogoLink = styled.a`
  display: block;
  width: 70px;
  opacity: 0.5;

  transform: translateY(22%);

  &:hover, &:active {
    opacity: 0.7;
  }
`;

const CopyrightContaier = styled.div`
  opacity: 0.7;
  font-size: 14px;
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 48px 24px 0 24px;
  max-width: 768px;
  width: 100%;

  ${({ hidden }) => hidden && css`
    display: none;
  `}
`;

const StatusRecords = styled.div`
`;

const ResultActionsRow = styled.div`
  padding-top: 24px;
`;

const ErrorContainer = styled.div`
  color: rgba(255, 255, 255, 0.9);
  background-color: rgb(255, 131, 101);
  border-radius: 3px;
  padding: 36px 48px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HappyContainer = styled.div`
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(74, 207, 172, 0.6);
  border-radius: 3px;
  padding: 36px 48px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AlertImageContainer = styled.div`
  width: 200px;

  ${media.lessThan("medium")`
    width: 150px;
  `}
`;

const AlertTitle = styled.div`
  padding: 16px 0;
  font-size: 40px;
  font-weight: bold;

  ${media.lessThan("medium")`
    font-size: 30px;
  `}
`;

const AlertDescription = styled.div`
  font-size: 18px;
  text-align: center;
`;

const AlertActionsRow = styled.div`
  padding-top: 24px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoaderImageContainer = styled.div`
  width: 200px;

  ${media.lessThan("medium")`
    width: 150px;
  `}

  animation: 0.7s ${spin} linear infinite;
`;

async function resolve(url) {
  const res = await fetch('/api/v1/resolve', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({ url }),
  });

  if (res.status >= 300) {
    throw { code: 'unexpected_status_code' };
  }

  const {
    error,
    log,
  } = await res.json();

  if (error) {
    throw { code: error };
  }

  if (!log || !Array.isArray(log)) {
    throw { code: 'unexpected_response' };
  }

  if (log.length === 1) {
    if (log[0].statusCode && log[0].statusCode < 400) {
      ga.trackEvent('User', 'Unresolved');
    } else if (log[0].error) {
      ga.trackEvent('User', 'Unresolved with error', log[0].error);
    }
  }

  return log;
}

function App() {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [log, setLog] = React.useState([]);

  const clearError = () => setError(null);
  const clearLog = () => setLog([]);

  useKonamiCode(() => {
    console.log('w00t!');
  }, { onlyOnce: true });

  const handleStartOver = () => {
    ga.trackEvent('User', 'Start over clicked');
    clearError();
    clearLog();
  };

  const handleSubmit = async ({ url }) => {
    clearError();
    clearLog();
    setLoading(true);

    try {
      const log = await resolve(url);
      setLog(log);
    } catch (err) {
      ga.trackEvent('User', 'Backend error seen', err.code);
      setError(err.code);
    } finally {
      setLoading(false);
    }
  };

  const hasResult = log && log.length > 0;
  const hasSingleResult = log && log.length === 1;
  const hasResultError = hasSingleResult && (
    (log[0].statusCode && log[0].statusCode >= 400)
    || (log[0].error)
  );

  React.useEffect(() => {
    ga.initialize();
    ga.pageView();
  }, []);

  return (
    <>
      <GlobalStyle />
      <AppContainer>
        <ContentContainers>
          <Container>
            <Header>
              <HeaderLogoContainer>
                <HeaderLogo />
              </HeaderLogoContainer>
              <HeaderTitle>
                Resolve This Link
              </HeaderTitle>
            </Header>
          </Container>
          {!hasResult && (
            <Container hidden={!!error}>
              <InputForm
                disabled={loading}
                onSubmit={handleSubmit}
              />
            </Container>
          )}
          {loading && (
            <Container>
              <LoadingContainer>
                <LoaderImageContainer>
                  <LoaderImage />
                </LoaderImageContainer>
              </LoadingContainer>
            </Container>
          )}
          {(error) && (
            <Container>
              <ErrorContainer>
                <AlertImageContainer>
                  <ErrorImage />
                </AlertImageContainer>
                <AlertTitle>
                  It Went South!
                </AlertTitle>
                <AlertDescription>
                  Something somehow went wrong somewhere and your request could not be completed. Better luck next time!
                </AlertDescription>
                <AlertActionsRow>
                  <Button
                    layout="secondary"
                    onClick={handleStartOver}
                  >
                    Start Over
                  </Button>
                </AlertActionsRow>
              </ErrorContainer>
            </Container>
          )}
          {hasResult && (
            <Container>
              <StatusRecords>
                {hasResult && (
                  <StatusRecord
                    isFirst
                    status="Input"
                    location={log[0].input}
                  />
                )}
                {hasResult && log.slice(0, -1).map(({
                  statusCode,
                  status,
                  method,
                  location,
                  error,
                }, index, list) => (
                  <StatusRecord
                    key={location}
                    isLast={index === list.length - 1}
                    statusCode={statusCode}
                    status={status}
                    method={method}
                    location={location}
                    error={error}
                  />
                ))}
              </StatusRecords>
              {log.length > 1 && (
                <ResultActionsRow>
                  <Button
                    onClick={handleStartOver}
                  >
                    Start Over
                  </Button>
                </ResultActionsRow>
              )}
              {hasResult && log.length === 1 && (
                <>
                  {hasResultError && (
                    <ErrorContainer>
                      <AlertImageContainer>
                        <ErrorImage />
                      </AlertImageContainer>
                      <AlertTitle>
                        It Went South!
                      </AlertTitle>
                      <AlertDescription>
                        Something somehow went wrong somewhere and your request could not be completed. Better luck next time!
                      </AlertDescription>
                      <AlertActionsRow>
                        <Button
                          layout="secondary"
                          onClick={handleStartOver}
                        >
                          Start Over
                        </Button>
                      </AlertActionsRow>
                    </ErrorContainer>
                  )}
                  {!hasResultError && (
                    <HappyContainer>
                      <AlertImageContainer>
                        <HappyImage />
                      </AlertImageContainer>
                      <AlertTitle>
                        Already Resolved
                      </AlertTitle>
                      <AlertDescription>
                        {'This link is already resolved. It doesn\'t redirect anywhere.'}
                      </AlertDescription>
                      <AlertActionsRow>
                        <Button
                          layout="secondary"
                          onClick={handleStartOver}
                        >
                          Start Over
                        </Button>
                      </AlertActionsRow>
                    </HappyContainer>
                  )}
                </>
              )}
            </Container>
          )}
        </ContentContainers>
        <Footer>
          <Container>
            <FooterContent>
              <CopyrightContaier>
                © Copyright lol
              </CopyrightContaier>
              <MaxLogoLink
                href="https://maxkueng.com/"
              >
                <MaxLogo />
              </MaxLogoLink>
            </FooterContent>
          </Container>
        </Footer>
      </AppContainer>
    </>
  );
}

export default App;
