import * as React from 'react';

export default function useKonamiCode(
  action,
  {
    sequence = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65],
    onlyOnce = false,
  } = {},
) {

  const input = React.useRef([]);

  const handleKeyUp = React.useCallback(({ keyCode }) => {
    input.current = [...input.current, keyCode].slice(sequence.length * -1);

    if (input.current.join('.') === sequence.join('.')) {
      if (onlyOnce) {
        document.removeEventListener('keyup', handleKeyUp);
      }

      if (action) {
        action();
      }
    }

  }, [input, action, sequence, onlyOnce]);

  React.useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyUp]);
}
