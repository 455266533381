import * as React from 'react';
import styled, { css } from 'styled-components';

const containerStyle = css`
  font-family: Roboto, sans-serif;
  background-color: rgba(54, 199, 208, 0.9);
  color: rgba(255, 255, 255, 0.9);

  height: 42px;
  border: none;
  outline: none;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1;
  padding: 0 16px;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &:hover, &:active, &:focus {
    background-color: rgba(54, 199, 208, 1);
    color: rgba(255, 255, 255, 1);
  }

  ${({ size }) => size === 'small' && css`
    height: 26px;
    font-size: 12px;
  `}

  ${({ layout }) => layout === 'secondary' && css`
    background-color: rgba(38, 40, 51, 0.6);

    &:hover, &:active, &:focus {
      background-color: rgba(38, 40, 51, 0.7);
    }
  `}
`;

const StyledButton = styled.button`
  ${containerStyle}
`;

const StyledAnchor = styled.a`
  ${containerStyle}
`;

export default function Button({
  children,
  element,
  disabled,
  type,
  size,
  layout,
  href,
  target,
  onClick,
}) {
  if (element === 'a') {
    return (
      <StyledAnchor
        href={href}
        target={target}
        size={size}
        layout={layout}
      >
        {children}
      </StyledAnchor>
    );
  }
  return (
    <StyledButton
      disabled={disabled}
      type={type}
      size={size}
      layout={layout}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
}

