import * as React from 'react';
import styled, { css } from 'styled-components';
import { useForm } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import * as ga from './ga';
import Button from './Button';

const Container = styled.div`
  width: 100%;
`;

const Form = styled.form`
  ${({ disabled }) => disabled && css`
    opacity: 0.3;
  `}
`;

const UrlInput = styled(TextareaAutosize)`
  width: 100%;
  height: 180px;
  background-color: rgba(54, 199, 208, 0.08);
  border: 1px solid rgba(54, 199, 208, 0.46);
  color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  line-break: anywhere;
  resize: vertical;

  outline: none;
  padding: 8px;

  &:hover, &:active, &:focus {
    background-color: rgba(54, 199, 208, 0.1);
    border: 1px solid rgba(54, 199, 208, 0.60);
    color: rgba(255, 255, 255, 1);
  }
`;

const InputError = styled.div`
  color: rgb(255, 164, 142);
  font-size: 14px;
  font-weight: 600;
  padding: 4px 0;
`;

const ActionRow = styled.div`
  padding-top: 16px;
`;

function getDefaultValue() {
  const hashParams = new window.URLSearchParams(window.location.hash.replace(/^#/, ''));
  return hashParams.get('url') || '';
}

export default function InputForm({
  disabled = false,
  onSubmit,
}) {
  const {
    handleSubmit,
    register,
    errors,
  } = useForm();

  const handleFormSubmit = ({ url }) => {
    onSubmit({
      url: url.trim(),
    });

    ga.trackEvent('User', 'URL submitted');
  };

  return (
    <Container>
      <Form
        disabled={disabled}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <UrlInput
          name="url"
          disabled={disabled}
          autoFocus
          spellCheck={false}
          placeholder="http://www.example.org/"
          defaultValue={getDefaultValue()}
          minRows={10}
          ref={register({
            required: "Required",
            pattern: {
              value: /^[\s]*https?:\/\/[^\s]+[\s]*$/i,
              message: 'Please enter a valid URL',
            }
          })}
        >
        </UrlInput>
        <div>
          {errors.url && (
            <InputError>
              {errors.url.message}
            </InputError>
          )}
        </div>
        <ActionRow>
          <Button
            type="submit"
            disabled={disabled}
          >
            Resolve
          </Button>
        </ActionRow>
      </Form>
    </Container>
  );
}
